.dragging .dragged {
  opacity: 0.5;
}

.dragging .dragOver {
  border: dashed 1px black;
  .drag * {
    pointer-events: none;
  }
}

.dragging .dragItem {
}
