/* @media screen and (min-width: 1151px) {
     #content {
        justify-content: center;    
    }
}

@media screen and (max-width: 1150px) {
    #content {
        justify-content: left;
        margin-left: 15px;
    }
} */


input::placeholder {
    opacity: 0.5;
    color: #b4b5b8!important;
}

