body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.Toastify__toast {
  border-radius: 10px !important;
  backdrop-filter: blur(10px)!important; /* Adjust the blur intensity as needed */
  background-color: rgba(255, 255, 255, 0.5)!important; /* Semi-transparent white */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2)!important; /* Optional: add shadow for depth */
}
.Toastify__progress-bar--info {
}
.Toastify__progress-bar--success {
  background: #71BF44 !important;
}
.Toastify__progress-bar--warning {
}
.Toastify__progress-bar--error {
  background: #FF4536 !important;
}


.Toastify__toast-theme--colored.Toastify__toast--info {
}
.Toastify__toast-icon--success {
  color: #71BF44 !important;
  background: #71BF44 !important;
}
.Toastify__toast-theme--colored.Toastify__toast--warning {
}
.Toastify__toast-theme--colored.Toastify__toast--error {
  background: #FF4536 !important;
}
